<template>
  <div class="bgcard">
    <div class="title">
      <div class="line"></div>
      <p>{{ $t("lang.electWorkOrder") }}</p>
    </div>
    <div class="question">
      <div v-for="(item, index) of problem" :key="index" @click="pushsub(item)">
        <div class="regclass">
          <div class="content">
            <p>{{ choicemsg[item.type] }}</p>
            <p>{{ choicemsgs[item.type] }}</p>
          </div>
          <img :src="item.srcurl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    console.log();
  },
  data() {
    return {
      problem: {
        userproblem: {
          type: 0,
          srcurl: require("@/assets/imgs/yhzcl.png")
        },
        liveproblem: {
          type: 1,
          srcurl: require("@/assets/imgs/zbzd.png")
        },
        flowproblem: {
          type: 2,
          srcurl: require("@/assets/imgs/lmtts.png")
        },
      },
    };
  },
  methods: {
    pushsub(msg) {
      switch (true) {
        case msg.type == "0":
          msg.type = 1;
          break;
        case msg.type == "1":
          msg.type = 2;
          break;
        case msg.type == "2":
          msg.type = 3;
          break;
      }
      this.$router.push("choiceorder");
      sessionStorage.setItem("type", msg.type);
    },
  },
  computed: {
    choicemsg() {
      return [
        this.$t("lang.userRegistrationClass"),
        this.$t("lang.liveBroadcastTerminalClass"),
        this.$t("lang.streamingMediaPushClass"),
      ];
    },
		choicemsgs() {
		  return [
		    this.$t("lang.Userloginregistration"),
		    this.$t("lang.Problemsrelatedtolivevideoondemand"),
		    this.$t("lang.Problemsrelatedtostreamingmediapush"),
		  ];
		},
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  width: 100%;
  height: 100%;
  border: 0px;
  box-sizing: border-box;
  padding: .39rem 0px 0px .39rem;
  border-radius: 2px;
  background: #222628;
}
.line {
  width: .04rem;
  height: .3rem;
  background: #b2b2b2;
  border-radius: 2px;
  margin-right: .11rem;
}
.title {
  display: flex;
  margin-bottom: .23rem;
  p {
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #b2b2b2;
    line-height: .3rem;
  }
}
.question {
  display: flex;
  .regclass,
  .videoclass,
  .flowclass {
    width: 3.93rem;
    height: 1.91rem;
    background: #161a1c;
    border-radius: .2rem;
    margin-right: .22rem;
    padding: .42rem .26rem 0px .39rem;
    display: flex;
    justify-content: space-between;
    img {
      width: .81rem;
      height: .81rem;
      background: #131719;
      opacity: 0.6;
      border-radius: 50%;
    }
    .content {
      P {
        &:first-of-type {
          font-size: .26rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: #b2b2b2;
          line-height: .35rem;
          margin-bottom: .38rem;
        }
        &:last-of-type {
          font-size: .16rem;
          font-family: PingFang-SC-Medium;
          font-weight: 500;
          color: #b2b2b2;
          line-height: .24rem;
          margin-left: -1px;
        }
      }
    }
    &:hover {
      transform: scale(1.02);
    }
  }
}
</style>
